import React, { useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import "../../src/components/AC-StaticPages/Alumni/Alumni.css";
import fimg1 from "../assets/img/advgd.webp";
import f2 from "../assets/img/g-logo.svg";
import fimg2 from "../assets/img/adv3d.jpg";
import fimg3 from "../assets/img/gadu.webp";
import f1 from "../assets/img/art/csd.webp";
import f3 from "../assets/img/art/gad.webp";
import f4 from "../assets/img/art/arvr.webp";
import f5 from "../assets/img/art/msc.webp";
import f6 from "../assets/img/art/adgd.webp";
import f9 from "../assets/img/art/adtd.webp";
import f10 from "../assets/img/art/dcac.webp";
import f11 from "../assets/img/art/dgdp.webp";
import f7 from "../assets/img/art/3dart.webp";
import f8 from "../assets/img/art/gdu.webp";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import msc from "../assets/img/gadp.jpg";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { emphasize, styled } from '@mui/material/styles';



import "./FeaturedProduct.css"



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledBreadcrumb = styled(Chip)(({ theme, Props }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}


function HomeContent() {

  
    const parentScrollContainerRef = useRef < HTMLDivElement | null > (null);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const [value1, setValue1] = React.useState(0);
  
    const handleChange1 = (event, newValue) => {
      setValue1(newValue);
    };
  
    const OPTIONS = { loop: true }
    const SLIDE_COUNT = 10
    const SLIDES = Array.from(Array(SLIDE_COUNT).keys())
    const isMobileState = useSelector(
      state => state.mainReducer.isMobile,
      shallowEqual
    );
  
  return (
    <>
      <section id="page-need_guidance-section" className="need-guidance-form flex items-end md:items-center md:min-h-[680px] lg:min-h-[800px] relative z-[11] -mt-[2px] overflow-hidden bgw" data-locomotive-section-id="page-need_guidance" data-locomotive-section-type="need_guidance">
        <div className="min-h-full h-full w-full container-full relative z-[3] py-4 md:py-8">
          <div className="d">
            <h2 className="mainHeadingTotal">FEATURED COURSES</h2>
            
          {/* <div className="">
              <div className="grid-cols-1 sm:grid-cols-2 grid gap-3 mt-3 sm:mt-5 lg:mt-6 card-list">
                <Link to="/courses/advanced-diploma-in-game-development/">
                  <li className="jBounceIn h-[320px] w-full bg-gradient200 p-2 rounded-large overflow-hidden relative group ht1">
                    <div className="z-10 relative flex flex-col justify-between h-full">
                      <div className="flex justify-between items-start">
                        <div>
                          <img src={f2} alt="logo" width="49" height="32" className="h-4" />
                        </div>
                      </div>
                      <div className="flex justify-center flex-col items-center text-txt100">
                        <p className='hdf hf1'> Advanced Diploma in <br />Game Development</p>
                        <p className="px-1 lg:px-2 mt-0 v-p400 ease-in-brand duration-600 opacity-0 max-h-0 group-hover:max-h-[80px] group-hover:opacity-100 group-hover:mt-3 text-center">
                        </p>
                      </div>
                    </div>
                    <div className="absolute top-0 left-0 h-full w-full image-card imgBx">
                      <img src={fimg1} srcSet={fimg1} alt="Concept Art" className="rounded-large object-cover h-full w-full scale-[1.01] group-hover:scale-[1.12] ease-in-brand duration-600" loading="lazy" />
                    </div>
                    
                  </li>
                </Link>
                <Link to="/courses/advanced-diploma-in-3d-game-art-digital-sculpting/">
                  <li className="jBounceIn h-[320px] w-full bg-gradient200 p-2 rounded-large overflow-hidden relative group ht1">
                    <div className="z-10 relative flex flex-col justify-between h-full">
                      <div className="flex justify-between items-start">
                        <div>
                          <img src={f2} alt="logo" width="49" height="32" className="h-4" />
                        </div>
                      </div>
                      <div className="flex justify-center flex-col items-center text-txt100">
                        <p className='hdf hf1'>Advanced Diploma in 3D Game Art & <br /> Digital Sculpting</p>
                        <p className="px-1 lg:px-2 mt-0 v-p400 ease-in-brand duration-600 opacity-0 max-h-0 group-hover:max-h-[80px] group-hover:opacity-100 group-hover:mt-3 text-center">
                        </p>
                      </div>
                    </div>
                    <div className="absolute top-0 left-0 h-full w-full image-card">
                      <img src={fimg2} srcSet={fimg2} alt="Game Art" className="rounded-large object-cover h-full w-full scale-[1.01] group-hover:scale-[1.12] ease-in-brand duration-600" loading="lazy" />
                    </div>
                    
                  </li>
                </Link>
                <Link to="/courses/diploma-in-game-development-with-unity/">
                  <li className="jBounceIn h-[320px] w-full bg-gradient200 p-2 rounded-large overflow-hidden relative group mb-sty ht1">
                    <div className="z-10 relative flex flex-col justify-between h-full">
                      <div className="flex justify-between items-start">
                        <div>
                          <img src={f2} alt="logo" width="49" height="32" className="h-4" />
                        </div>
                      </div>
                      <div className="flex justify-center flex-col items-center text-txt100">
                        <p className='hdf hf1'>Diploma in Game Development <br /> with Unity</p>
                        <p className="px-1 lg:px-2 mt-0 v-p400 ease-in-brand duration-600 opacity-0 max-h-0 group-hover:max-h-[80px] group-hover:opacity-100 group-hover:mt-3 text-center">
                        </p>
                      </div>
                    </div>
                    <div className="absolute top-0 left-0 h-full w-full image-card">
                      <img src={fimg3} srcSet={fimg3} alt="VFX" className="rounded-large object-cover h-full w-full scale-[1.01] group-hover:scale-[1.12] ease-in-brand duration-600" loading="lazy" />
                    </div>
                    
                  </li>
                </Link>
                <Link to="/courses/diploma-in-game-design-production/">
                  <li className="jBounceIn h-[320px] w-full bg-gradient200 p-2 rounded-large overflow-hidden relative group mb-sty ht1">
                    <div className="z-10 relative flex flex-col justify-between h-full">
                      <div className="flex justify-between items-start">
                        <div>
                          <img src={f2} alt="logo" width="49" height="32" className="h-4" />
                        </div>
                      </div>
                      <div className="flex justify-center flex-col items-center text-txt100">
                        <p className='hdf hf1'>Diploma in Game Design <br /> & Production</p>
                        <p className="px-1 lg:px-2 mt-0 v-p400 ease-in-brand duration-600 opacity-0 max-h-0 group-hover:max-h-[80px] group-hover:opacity-100 group-hover:mt-3 text-center"></p>
                      </div>
                    </div>
                    <div className="absolute top-0 left-0 h-full w-full image-card">
                      <img src={msc} srcSet={msc} alt="Concept Art" className="rounded-large object-cover h-full w-full scale-[1.01] group-hover:scale-[1.12] ease-in-brand duration-600" loading="lazy" />
                    </div>
                  </li>
                </Link>
              </div>
              <Link style={{ display: "flex", justifyContent: "center" }} to={"/courses/"}> <button style={{ width: "300px", float: "right", marginTop: "30px" }} className='three button brand size200 w-full sm:w-auto' type="button">View All Courses <FiArrowRight style={{ marginLeft: "7px" }} /></button></Link>
            </div>  */}
            {isMobileState ?  <div id="Featured-Mentorsd" backgroundColor="brown" height="150vh">
            
           
            <div className="maincolls clear-fix">
            <div className="cardss maincolltwo">
                <figuress>
                  <img src={f1} alt="f1" style={{borderRadius:"8px"}}/>
                  <figcaptionss>


                    <div className="mainggames"><p>Bachelor's in Computer Science & Game Development</p></div>
                    <div className="viewc"><a href="/courses/bachelors-in-computer-science-and-game-development/">View Course</a></div>
                  </figcaptionss>
                </figuress>
              </div>
              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f3} alt="f3" style={{borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Bachelor's in Game Art & Design</p><div className="viewc"><a href="/courses/bachelors-in-game-art-and-design/">View Course</a></div>
                    </div>

                  </figcaptionss>
                </figuress>
              </div>
              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f4} alt="f4" style={{borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Bachelor's in Augmented Reality & Virtual Reality</p> <div className="viewc"><a href="/courses/bachelors-in-augmented-reality-and-virtual-reality/">View Course</a></div>
                    </div>

                  </figcaptionss>
                </figuress>
              </div>

              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f5} alt="f5" style={{borderRadius:"8px"}} />
                  <figcaptionss>


                    <div className="mainggames"><p>Master's in Game technology</p><div className="viewc"><a href="/courses/masters-in-game-technology/">View Course</a></div></div>
                  </figcaptionss>
                </figuress>
              </div>
              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f6} alt="f6" style={{borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Advanced Diploma in
                    Game Development</p><div className="viewc"><a href="/courses/advanced-diploma-in-game-development/">View Course</a></div></div>

                  </figcaptionss>
                </figuress>
              </div>
              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f7} alt="f7" style={{borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p> Advanced Diploma in
                    3D Game Art & Digital Sculpting</p><div className="viewc"><a href="/courses/advanced-diploma-in-3d-game-art-digital-sculpting/">View Course</a></div></div>

                  </figcaptionss>
                </figuress>
              </div>
              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f9} alt="f9" style={{borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Advanced Diploma in
                    Traditional & Digital Concept Art</p><div className="viewc"><a href="/courses/advanced-diploma-in-traditional-digital-art/">View Course</a></div></div>

                  </figcaptionss>
                </figuress>
              </div>
             
              
              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f11} alt="f11" style={{borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Diploma in Game Design & Production</p><div className="viewc"><a href="/courses/diploma-in-game-design-production/">View Course</a></div></div>

                  </figcaptionss>
                </figuress>
              </div>
              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f8} alt="f8" style={{borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Diploma in Game Development with Unity</p><div className="viewc"><a href="/courses/diploma-in-game-development-with-unity/">View Course</a></div></div>

                  </figcaptionss>
                </figuress>
              </div>
              <div className="cardss maincolltwo">
                <figuress>
                  <img src={f10} alt="f10" style={{borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Diploma in
                    3D Environment Art For Games</p><div className="viewc"><a href="/courses/diploma-in-3d-environment-art-creation-for-games/">View Course</a></div></div>

                  </figcaptionss>
                </figuress>
              </div>
              


            </div>
         
          </div>
                 : <div id="Featured-Mentorsd" backgroundColor="brown" height="150vh">
            
                          <div className="maincoll clear-fix">
                            <div className="cardss maincolltwo">
                              <figures>
                                <img src={f1} alt="f1" style={{borderRadius:"8px"}} />
                                <figcaptionss>
            
            
                                  <div className="mainggames"><p>Bachelor's in Computer Science & Game Development</p></div>
                                  <div className="viewc"><a href="/courses/bachelors-in-computer-science-and-game-development/">View Course</a></div>
                                </figcaptionss>
                              </figures>
                            </div>
                            <div className="cardss maincolltwo">
                              <figures>
                                <img src={f3} alt="f3" style={{borderRadius:"8px"}} />
                                <figcaptionss>
                                  <div className="mainggames"><p>Bachelor's in Game Art & Design</p><div className="viewc"><a href="/courses/bachelors-in-game-art-and-design/">View Course</a></div>
                                  </div>
            
                                </figcaptionss>
                              </figures>
                            </div>
                            <div className="cardss maincolltwo">
                              <figures>
                                <img src={f4} alt="f4" style={{borderRadius:"8px"}} />
                                <figcaptionss>
                                  <div className="mainggames"><p>Bachelor's in Augmented Reality & Virtual Reality</p> <div className="viewc"><a href="/courses/bachelors-in-augmented-reality-and-virtual-reality/">View Course</a></div>
                                  </div>
            
                                </figcaptionss>
                              </figures>
                            </div>
            
                            <div className="cardss maincolltwo">
                              <figures>
                                <img src={f5} alt="f5" style={{borderRadius:"8px"}} />
                                <figcaptionss>
            
            
                                  <div className="mainggames"><p>Master's in Game technology</p><div className="viewc"><a href="/courses/masters-in-game-technology/">View Course</a></div></div>
                                </figcaptionss>
                              </figures>
                            </div>
                          </div>
                          <div className="maincoll clear-fix">
                           
                            <div className="cardss maincolltwo">
                              <figures>
                                <img src={f6} alt="f6"  style={{width:"100%",borderRadius:"8px"}}/>
                                <figcaptionss>
                                  <div className="mainggames"><p>Advanced Diploma in
                                  Game Development</p><div className="viewc"><a href="/courses/advanced-diploma-in-game-development/">View Course</a></div></div>
            
                                </figcaptionss>
                              </figures>
                            </div>
                 
                            <div className="cardss maincolltwo">
                              <figures>
                                <img src={f7} alt="f7"  style={{width:"100%",borderRadius:"8px"}}/>
                                <figcaptionss>
                                  <div className="mainggames"><p> Advanced Diploma in
                                  3D Game Art & Digital Sculpting</p><div className="viewc"><a href="/courses/advanced-diploma-in-3d-game-art-digital-sculpting/">View Course</a></div></div>
            
                                </figcaptionss>
                              </figures>
                            </div>
                            <div className="cardss maincolltwo">
                                <figures>
                               <img src={f9} alt="f9" style={{width:"100%",borderRadius:"8px"}}/>
                                     <figcaptionss>
                                    <div className="mainggames"><p>Advanced Diploma in
                                      Traditional & Digital Concept Art</p><div className="viewc"><a href="/courses/advanced-diploma-in-traditional-digital-art/">View Course</a></div></div>

                                    </figcaptionss>
                                  </figures>
                                  </div>
              <div className="cardss maincolltwo">
                <figures>
                  <img src={f8} alt="f8" style={{width:"100%",borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Diploma in Game Development with Unity</p><div className="viewc"><a href="/courses/diploma-in-game-development-with-unity/">View Course</a></div></div>

                  </figcaptionss>
                </figures>
              </div>  
              </div>
              <div className="maincoll clear-fix">
            
              <div className="cardss maincolltwo">
                <figures>
                  <img src={f10} alt="f10" style={{width:"100%",borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Diploma in
                    3D Environment Art For Games</p><div className="viewc"><a href="/courses/diploma-in-3d-environment-art-creation-for-games/">View Course</a></div></div>

                  </figcaptionss>
                </figures>
              </div>
              <div className="cardss maincolltwo">
                <figures>
                  <img src={f11} alt="f11" style={{width:"100%",borderRadius:"8px"}}/>
                  <figcaptionss>
                    <div className="mainggames"><p>Diploma in Game Design & Production</p><div className="viewc"><a href="/courses/diploma-in-game-design-production/">View Course</a></div></div>

                  </figcaptionss>
                </figures>
              </div>
              </div>
            
            
                       
                        </div>}
                        <Link style={{ display: "flex", justifyContent: "center" }} to={"/courses/"}> <button style={{ width: "300px", float: "right", marginTop: "30px" }} className='three button brand size200 w-full sm:w-auto' type="button">View All Courses <FiArrowRight style={{ marginLeft: "7px" }} /></button></Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeContent;
