import React, { useState, useEffect } from 'react';
import Async from "react-code-splitting";
import HomeContent from "./HomeContent";
import AboutBsp from "./AboutBsp";
import FeaturedProduct from "./FeaturedProduct";
import FeaturedProducts from "./FeaturedProducts";
import TopRequiters from "./topRequiters";
import Awards from "./awards";
import Buzzing from "./Buzzing";
import preLoaderImg from "../assets/img/BSPAnimated.gif"
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { Helmet } from "react-helmet";

import { CustomPreloader } from 'react-preloaders';



import EmblaCarousel from './EmblaCarousel.js'


const Footer = () => (
  <Async load={import("../components/AC-Footer/Footer.jsx")} />
);



function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

function Main({ active, props }) {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const data = window.localStorage.getItem('MY_APP_STATE');
    if (data !== null) setShowBanner(JSON.parse(data));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('MY_APP_STATE', JSON.stringify(showBanner));
  }, [showBanner]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowBanner(false);
      setLoading(false);
    }, 3000);

  }, []);

  const OPTIONS = { loop: true }
  const SLIDE_COUNT = 5
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())


  return (
    <>
      <Helmet>
        <title>Best Game Development College In India</title>
        <meta name="description" content="Join the top game design and development college in Hyderabad. Explore game development courses & game design courses to kickstart your gaming career." />
        <link rel="canonical" href="https://www.backstagepass.co.in/" />
      </Helmet>
      <div className="row mb0">
      {window.location.pathname === "/" ? 
      <a href ="#ribbon">
         <div className='scallop animate'>
          <a>
            <div className='stripd'>
            <div className='adopen'><p>Admissions Open! </p></div>
 <div className='mainhdb'>
           <p><b>Bachelor's/Master's Programs :</b> <strong> Apply now for 2025 intake!</strong></p><br/>
       <p><b>Diploma/Advanced Diploma Programs :</b> <strong> Limited seats for the April and May 2025 batches. Apply now!</strong> </p>
           
           </div>
           </div>
           </a>
   </div>
   </a> : null }
        <div className="mainPanel">
          <div className="item pr9">
            <div id="owl-banners" className="">
            {window.location.pathname === "/" ? 

              <EmblaCarousel slides={SLIDES} options={OPTIONS} /> : null }



            </div>
          </div>
          <div>
            <section id="there's-still-time-to-apply-for-2024" className="banner banner--image siva pr9">

              <div className="container1">
                <h1 className="heading heading--white banner__heading">
                  Trailblazers of gaming education in India since 2010
                </h1>
              </div>
            </section>
          </div>
          <div className="">




            <AboutBsp />
            <FeaturedProduct />
            <TopRequiters />

            <div className='chapter' id="chapter1">
              <div className="bg-c-gradient300 absolute w-full h-[101%] -top-[1px] -bottom-[1px] left-0 z-[2]"></div>
              <FeaturedProducts />
              <Awards />
            </div>


          </div>



          <Buzzing />
          <div id="ribbon">
            <HomeContent />
            </div>
          <Footer />
        </div>
      </div>
      {showBanner && (
        <CustomPreloader customLoading={loading} background="#000000">
          <img src={preLoaderImg} alt="loading" />
        </CustomPreloader>)}

      {/* <button className={"signupHide"} onClick={() => setShowBanner(false)}>
  Hide
</button> */}
    </>
  );
}

export default Main;
