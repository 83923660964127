import React, { useCallback, useEffect, useRef } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import { useSelector, shallowEqual } from "react-redux";


import newban0 from "../assets/img/banners/gaming1.webp";
import newban1 from "../assets/img/banners/gaming.webp";
import newban2 from "../assets/img/banners/learning.webp";
import newban3 from "../assets/img/banners/alumni.webp";
import mbnewban0 from "../assets/img/mobilebanner/homepagebanner/gemawards.webp";
import mbnewban1 from "../assets/img/mobilebanner/homepagebanner/gaming.webp";
import mbnewban2 from "../assets/img/mobilebanner/homepagebanner/learning.webp";
import mbnewban3 from "../assets/img/mobilebanner/homepagebanner/alumni.webp";


const TWEEN_FACTOR_BASE = 0.84

const numberWithinRange = (number, min, max) =>
  Math.min(Math.max(number, min), max)

const EmblaCarousel = (props) => {
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );
  const { options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const tweenFactor = useRef(0)

  

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const setTweenFactor = useCallback((emblaApi) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
  }, [])

  const tweenOpacity = useCallback((emblaApi, eventName) => {
    const engine = emblaApi.internalEngine()
    const scrollProgress = emblaApi.scrollProgress()
    const slidesInView = emblaApi.slidesInView()
    const isScrollEvent = eventName === 'scroll'

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress
      const slidesInSnap = engine.slideRegistry[snapIndex]

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target()

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target)

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress)
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress)
              }
            }
          })
        }

        const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current)
        const opacity = numberWithinRange(tweenValue, 0, 1).toString()
        emblaApi.slideNodes()[slideIndex].style.opacity = opacity
      })
    })
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    setTweenFactor(emblaApi)
    tweenOpacity(emblaApi)
    emblaApi
      .on('reInit', setTweenFactor)
      .on('reInit', tweenOpacity)
      .on('scroll', tweenOpacity)
      .on('slideFocus', tweenOpacity)
  }, [emblaApi, tweenOpacity])

  return (
    <div>
  
    <div className="embla">
      
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
        <div className="embla__slide" key={0}>
              <img
                className="embla__slide__img"
                src={isMobileState ? mbnewban0 : newban1}
                alt="Home Banner" style={{objectPosition:isMobileState ? "center" :"top", height:isMobileState ? "auto" : "auto", marginTop:isMobileState ? "0px" : "0px"}}
              />
            </div>
        <div className="embla__slide" key={1}>
              <img
                className="embla__slide__img"
                src={isMobileState ? mbnewban1 : newban0}
                alt="Home Banner" style={{ height:isMobileState ? "auto" : "41rem",objectFit:"cover",objectPosition:"top", marginTop:isMobileState ? "0px" :"0px"}}
              />
            </div>
       
            <div className="embla__slide" key={2}>
              <img
                className="embla__slide__img"
                src={isMobileState ? mbnewban2 : newban2}
                alt="Home Banner"
                 style={{ height:isMobileState ? "auto" : "41rem",objectFit:"cover",objectPosition:"top", marginTop:isMobileState ? "0px" :"0px"}}
              />
            </div>

            <div className="embla__slide" key={3}>
              <img
                className="embla__slide__img"
                src={isMobileState ? mbnewban3 : newban3}
                alt="Home Banner"
                style={{ height:isMobileState ? "auto" : "41rem",objectFit:"cover",objectPosition:"top", marginTop:isMobileState ? "0px" :"0px"}}
              />
            </div>

            
         
        </div>
      </div>
      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        {/* <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div> */}
      </div>
      
    </div>
    </div>
  )
}

export default EmblaCarousel
